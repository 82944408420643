
function SubHeading(props) {

    return (
        <h3 class="text-l text-gray-800 font-bold  mb-3">
              {props.label}
            </h3>
    );
}

export default SubHeading;